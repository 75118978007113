<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-select
          v-model="form.channel_id"
          :items="channelOptions"
          item-text="title"
          item-value="value"
          label="Select Channel"
          outlined
          hide-details
          :disabled="!!resource"
          class="mt-5"
        ></v-select>

        <v-select
          v-model="form.distributor_id"
          class="mt-4"
          label="Distributor"
          :items="distributorOptions"
          item-text="label"
          item-value="value"
          :disabled="!!resource"
          outlined
        ></v-select>

        <v-text-field
          v-model="form.endpoint"
          label="Endpoint"
          outlined
          :rules="[required]"
          class="mt-5"
        />

        <h3>Optional Headers: </h3>
        <free-object-field
          v-model="form.headers"
          btn-text="Add Headers key value ..."
        />

        <v-switch
          v-model="form.active"
          color="primary"
          :label="form.active ? 'Active' : 'Inactive'"
        ></v-switch>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { FreeObjectField } from '@/components'
import { useSortTitle } from '@/composables'
import channelStore from '@/modules/channel/store'
import { required } from '@core/utils/validation'
import { } from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { useDistributor } from '@/composables'

export default {
  components: {
    FreeObjectField,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    // Get value from resource prop, use initValue if not exist
    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })

    const rules = props.resource ? props.resource.rules : false

    const channelOptions = channelStore.state.channels
      .map(channel => ({ title: `${channel.code}`, value: channel.id }))
      .sort(useSortTitle)

    const initialForm = {
      ...field('channel_id', null),
      ...field('distributor_id', null),
      ...field('headers', {}),
      ...field('endpoint', ''),
      ...field('active', true),
      ...field('headers', {}),
    }

    if (!initialForm.headers) {
      initialForm.headers = {}
    }

    if (Array.isArray(initialForm.headers)) {
      initialForm.headers = {}
    }

    const form = ref({
      ...initialForm,
    })
    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const useTranslatableEditor = computed(() => form.value.key.includes('html') || form.value.key.includes('formatted'))

    const { distributorOptions, fetchDistributors } = useDistributor()

    const { channel_id } = initialForm
    if (channel_id) {
      fetchDistributors({ channel_id, take: 999 })
    }

    watch(computed(() => form.value.channel_id), channel_id => fetchDistributors({ channel_id, take: 999 }))

    return {
      form,
      formElem,
      validate,
      useTranslatableEditor,
      required,
      channelOptions,
      distributorOptions,
      fetchDistributors,
    }
  },
}
</script>